import {useMutation} from '@apollo/client'
import {Grid2, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {useState} from 'react'
import {Link, Loading, useTranslate} from 'react-admin'
import {Navigate} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedHookForm'
import RichTranslationText from '../components/RichTranslationText'
import theme from '../theme'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {actionErrorCode} from '../utils/errors'

const ForgotPasswordPage = () => {
  const translate = useTranslate()
  const styles = useStyles()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const [isResetRequestSent, setIsResetRequestSent] = useState(false)
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET_MUTATION)
  const validate = values => ({
    errors: {
      ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
        emailAddress: {
          message: translate('validations.emailAddress'),
          type: 'validation',
        },
      }),
    },
    values,
  })
  const submit = variables => requestPasswordReset({variables})
    .then(() => setIsResetRequestSent(true))
    .catch(e => {
      const errorCode = actionErrorCode(e)
      const errorMessage = translate(
        errorCode ? `errors.codes.${errorCode}` : 'ra.message.error'
      )
      return {errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Navigate to="/" />
  }
  return (
    <EmptyLayout>
      {isResetRequestSent ? (
        <Grid2
          alignItems="stretch"
          className={styles.container}
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Grid2>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.requestPasswordReset.resetRequestSent.title')}
            </Typography>
          </Grid2>
          <Grid2 className={styles.requestResetSuccessful}>
            <Typography align="center" color="textSecondary" gutterBottom>
              {translate('pages.requestPasswordReset.resetRequestSent.subtitle')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              {translate('pages.requestPasswordReset.resetRequestSent.warn')}
            </Typography>
            <Typography align="center" color="textSecondary">
              <RichTranslationText
                tags={{
                  link: match => (
                    // @ts-ignore
                    <Link
                      color="secondary"
                      onClick={() => setIsResetRequestSent(false)}
                      underline="none"
                    >
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.resetRequestSent.resend"
              />
            </Typography>
          </Grid2>
          <Grid2>
            <Typography align="center">
              <Link color="secondary" to="/login" underline="none">
                {translate('pages.requestPasswordReset.resetRequestSent.login')}
              </Link>
            </Typography>
          </Grid2>
        </Grid2>
      ) : (
        <Form submit={submit} validate={validate}>
          <Grid2 alignItems="stretch" container direction="column" spacing={2}>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.requestPasswordReset.title')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              <RichTranslationText
                tags={{
                  register: match => (
                    <Link to="/signup" underline="none" variant="body1">
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.register"
              />
            </Typography>
          </Grid2>
          <Grid2 alignItems="stretch" container direction="column" spacing={3}>
            <SubmitErrorMessage />
            <Grid2>
              <TextField
                label={translate('pages.requestPasswordReset.inputs.emailAddress.label')}
                name="emailAddress"
                parse={v => v?.toLowerCase() ?? ''}
                placeholder={
                  translate('pages.requestPasswordReset.inputs.emailAddress.placeholder')
                }
                type="email"
              />
            </Grid2>
            <Grid2>
              <SubmitButton>
                {translate('pages.requestPasswordReset.submit')}
              </SubmitButton>
            </Grid2>
          </Grid2>
          <Grid2 my="24px">
            <Typography align="center" color="textPrimary" variant="body1">
              <RichTranslationText
                tags={{
                  login: match => (
                    <Link to="/login" underline="none">{match}</Link>
                  ),
                }}
                translationKey="pages.requestPasswordReset.login"
              />
            </Typography>
          </Grid2>
        </Form>
      )}
    </EmptyLayout>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiGrid': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: '100%',
    margin: 'auto',
    width: theme.remSpacing(56),
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
  requestResetSuccessful: {
    alignSelf: 'center',
    width: theme.remSpacing(45),
  },
}))

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation($emailAddress: String!){requestPasswordReset(emailAddress: $emailAddress)}
`

export default ForgotPasswordPage
