import {useMutation} from '@apollo/client'
import {Grid, Hidden, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {useRedirect, useTranslate} from 'react-admin'

import BauenlebenLogo from '../assets/imgs/bauenLebenLogo.svg'
import BuderusLogo from '../assets/imgs/buderusLogo.svg'
import DammisolLogo from '../assets/imgs/dammisolLogo.svg'
import HotzeLogo from '../assets/imgs/hotzeLogo.svg'
import IntergastLogo from '../assets/imgs/intergastLogo.svg'
import MayroseLogo from '../assets/imgs/mayroseLogo.svg'
import Tankpool24Logo from '../assets/imgs/tankpool24Logo.svg'
import TeamAgLogo from '../assets/imgs/teamAgLogo.svg'
import WegoLogo from '../assets/imgs/wegoLogo.svg'
import AppLanguageSetup from '../components/AppLanguageSetup'
import EmptyLayout from '../components/EmptyLayout'
import {
  Form, SelectField, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedHookForm'
import useAuthWatcher from '../hooks/useAuthWatcher'
import theme from '../theme'

const CompanyDetailsPage = () => {
  useAuthWatcher()
  const styles = useStyles()
  const redirect = useRedirect()
  const translate = useTranslate()
  const companySizeOptions = COMPANY_SIZE_OPTIONS.map(option => ({
    label: translate(`pages.companyDetails.inputs.companySize.options.${option}`),
    value: option,
  }))
  const industryOptions = INDUSTRY_OPTIONS.map(option => ({
    label: translate(`pages.companyDetails.inputs.industry.options.${option}`),
    value: option,
  }))
  const validate = values => ({
    errors: {
      ...(!values.companyName && {
        companyName: {
          message: translate('validations.companyName'),
          type: 'validation',
        },
      }),
      ...(!values.companySize && {
        companySize: {
          message: translate('validations.companySize'),
          type: 'validation',
        },
      }),
      ...(!values.industry && {
        industry: {
          message: translate('validations.industry'),
          type: 'validation',
        },
      }),
    },
    values,
  })
  const [saveCompanyDetails] = useMutation(SAVE_COMPANY_DETAILS_MUTATION)
  const submit = variables => saveCompanyDetails({variables})
    .then(() => redirect('/'))
    .catch(e => {
      const errorCode = e.errors?.[0]?.extensions?.[0]?.code
      const errorMessage = translate(
        errorCode ? `pages.login.errors.${errorCode}` : 'ra.message.error'
      )
      return {errorMessage}
    })
  return (
    <EmptyLayout>
      <AppLanguageSetup />
      <Grid className={styles.root} container>
        <Grid item xl={6} xs={12}>
          <Form className={styles.form} submit={submit} validate={validate}>
            <Grid item>
              <Typography gutterBottom variant="h2">
                {translate('pages.companyDetails.title')}
              </Typography>
              <Typography color="textSecondary">
                {translate('pages.companyDetails.subtitle')}
              </Typography>
            </Grid>
            <Grid item>
              <SubmitErrorMessage />
            </Grid>
            <Grid item>
              <TextField
                label={translate('pages.companyDetails.inputs.companyName.label')}
                name="companyName"
                placeholder={
                  translate('pages.companyDetails.inputs.companyName.placeholder')
                }
                type="text"
              />
            </Grid>
            <Grid item>
              <SelectField
                label={translate('pages.companyDetails.inputs.companySize.label')}
                name="companySize"
                options={companySizeOptions}
                placeholder={
                  translate('pages.companyDetails.inputs.companySize.placeholder')
                }
              />
            </Grid>
            <Grid item>
              <SelectField
                label={translate('pages.companyDetails.inputs.industry.label')}
                name="industry"
                options={industryOptions}
                placeholder={
                  translate('pages.companyDetails.inputs.industry.placeholder')
                }
              />
            </Grid>
            <Grid item>
              <SubmitButton>{translate('pages.companyDetails.button')}</SubmitButton>
            </Grid>
          </Form>
        </Grid>
        <Hidden xlDown>
          <Grid
            alignItems="stretch"
            className={styles.trustedCompanies}
            container
            direction="column"
            item
            justifyContent="center"
            xs={6}
            zeroMinWidth
          >
            <Grid item>
              <Typography align="center" variant="h3">
                {translate('pages.companyDetails.trustedCompaniesTitle')}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-evenly" spacing={8} wrap="wrap">
              {TRUSTED_COMPANIES_LOGOS.map((Logo, i) => (
                <Grid item key={i} xs={4}><Logo/></Grid>
              ))}
            </Grid>
            <Grid container direction="column" item spacing={2}>
              <Grid item>
                <Typography align="center">
                  “Vor Flinkit musste ich die Kunden immer erst fragen, ob ich die <br/>
                  Kontaktdaten weiterleiten darf. Jetzt weise ich sie einfach dem <br/>
                  Fachberater zu, genial! Und in meiner Urlaubszeit wird der Kunde <br/>
                  auch bedient. Noch besser!”
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="center" color="textSecondary" variant="body2">
                  Sarah Kasimir, Marketing, Anton Mayrose GmbH
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </EmptyLayout>
  )
}

const SAVE_COMPANY_DETAILS_MUTATION = gql`
  mutation(
    $companyName: String!
    $companySize: String!
    $industry: String!
  ){
    saveCompanyDetails(
      companyName: $companyName
      companySize: $companySize
      industry: $industry
    )
  }
`

const TRUSTED_COMPANIES_LOGOS = [
  BauenlebenLogo,
  BuderusLogo,
  DammisolLogo,
  HotzeLogo,
  IntergastLogo,
  MayroseLogo,
  Tankpool24Logo,
  TeamAgLogo,
  WegoLogo,
]

const COMPANY_SIZE_OPTIONS = ['1-10', '11-50', '51-200', '201-500', '500+']

const INDUSTRY_OPTIONS = [
  'AUTOMOTIVE',
  'CONSTRUCTION',
  'DIGITAL_AGENCY',
  'EDUCATION_SERVICES',
  'GOVERNMENT',
  'HEALTHCARE',
  'HOTELS',
  'HOUSEHOLD',
  'INSURANCE',
  'ONLINE_MARKETPLACES',
  'OTHER',
  'PROFESSIONAL_SERVICES',
]

const useStyles = makeStyles(() => ({
  form: {
    '& > .MuiGrid-root': {
      width: '100%',
    },
  },
  root: {
    height: '100%',
    margin: 0,
    overflowX: 'hidden',
    width: '100%',
  },
  trustedCompanies: {
    '& svg': {
      width: '100%',
    },
    background: theme.palette.info.light,
    gap: theme.remSpacing(8),
  },
}))

export default CompanyDetailsPage
