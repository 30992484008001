import {Grid2, Typography} from '@mui/material'
import {Link, Loading, useAuthProvider, useTranslate} from 'react-admin'
import {Navigate} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, PasswordFieldError, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedHookForm'
import RichTranslationText from '../components/RichTranslationText'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'

const SignupPage = () => {
  const translate = useTranslate()
  const authProvider = useAuthProvider()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const validate = values => ({
    errors: {
      ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
        emailAddress: {
          message: translate('validations.emailAddress'),
          type: 'validation',
        },
      }),
      ...(!values.fullName && {
        fullName: {
          message: translate('validations.fullName'),
          type: 'validation',
        },
      }),
      ...(!(
        (/\d/).test(values.password) &&
        values.password.length >= 8 &&
        (/\p{Ll}/u).test(values.password) &&
        (/\p{Lu}/u).test(values.password)
      ) && {
        password: {
          message: translate('validations.password.missingSomeChars'),
          type: 'validation',
        },
      }),
    },
    values,
  })
  const submit = values => {
    const {fullName, ...rest} = values
    const [firstName, lastName = ' '] = fullName.split(' ')
    return authProvider
      ?.signup({firstName, lastName, ...rest})
      .then(() => {window.location.href = "/company-details"})
      .catch(e => {
        const errorCode = e.errors?.[0]?.extensions?.[0]?.code
        const errorMessage = translate(
          errorCode ? `pages.signup.errors.${errorCode}` : 'ra.message.error'
        )
        return {errorMessage}
      })
  }
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Navigate to="/" />
  }
  return (
    <EmptyLayout>
      <Form submit={submit} validate={validate}>
        <Grid2 alignItems="stretch" container direction="column" spacing={2}>
          <Typography align="center" gutterBottom variant="h2">
            {translate('pages.signup.title')}
          </Typography>
          <Typography align="center" color="textSecondary" gutterBottom>
            <RichTranslationText
              tags={{
                login: match => (
                  <Link to="/login" underline="none"> {match}</Link>
                ),
              }}
              translationKey="pages.signup.links.login"
            />
          </Typography>
        </Grid2>
        <Grid2 alignItems="stretch" container direction="column" spacing={3}>
          <SubmitErrorMessage />
          <Grid2>
            <TextField
              label={translate('pages.signup.inputs.fullName.label')}
              name="fullName"
              placeholder={translate('pages.signup.inputs.fullName.placeholder')}
              type="text"
            />
          </Grid2>
          <Grid2>
            <TextField
              label={translate('pages.signup.inputs.emailAddress.label')}
              name="emailAddress"
              parse={v => v?.toLowerCase() ?? ''}
              placeholder={translate('pages.signup.inputs.emailAddress.placeholder')}
              type="email"
            />
          </Grid2>
          <Grid2>
            <PasswordField
              label={translate('pages.signup.inputs.password.label')}
              placeholder={translate('pages.signup.inputs.password.placeholder')}
            />
            <PasswordFieldError />
          </Grid2>
          <Grid2>
            <SubmitButton>
              {translate('pages.signup.button')}
            </SubmitButton>
          </Grid2>
        </Grid2>
        <Grid2 my="24px">
          <Typography align="center" color="textPrimary" variant="body1">
            <RichTranslationText
              tags={{
                forgot: match => (
                  <Link to="/forgot-password" underline="none"> {match}</Link>
                ),
              }}
              translationKey="pages.signup.links.forgotPassword"
            />
          </Typography>
        </Grid2>
      </Form>
    </EmptyLayout>
  )
}

export default SignupPage
