import {Grid2, Typography} from '@mui/material'
import {Link, Loading, useAuthProvider, useTranslate} from 'react-admin'
import {Navigate, useNavigate} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedHookForm'
import RichTranslationText from '../components/RichTranslationText'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'


const LoginPage = () => {
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const translate = useTranslate()
  const authProvider = useAuthProvider()
  const navigate = useNavigate()
  const validate = values => ({
    errors: {
      ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
        emailAddress: {
          message: translate('validations.emailAddress'),
          type: 'validation',
        },
      }),
      ...(!values.password && {
        password: {
          message: translate('validations.password.required'),
          type: 'validation',
        },
      }),
    },
    values,
  })
  const submit = values => authProvider?.login(values)
    .then(() => navigate('/'))
    .catch(e => {
      const errorCode = e.errors?.[0]?.extensions?.[0]?.code
      const errorMessage = translate(
        errorCode ? `pages.login.errors.${errorCode}` : 'ra.message.error'
      )
      return {errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Navigate to="/" />
  }
  return (
    <EmptyLayout>
      <Form submit={submit} validate={validate}>
        <Grid2 alignItems="stretch" container direction="column" spacing={2}>
          <Typography align="center" gutterBottom variant="h2">
            {translate('pages.login.title')}
          </Typography>
          <Typography align="center" color="textSecondary" gutterBottom>
            <RichTranslationText
              tags={{
                signup: match => (
                  <Link to="/signup" underline="none">{match}</Link>
                ),
              }}
              translationKey="pages.login.links.signup"
            />
          </Typography>
        </Grid2>
        <Grid2 alignItems="stretch" container direction="column" spacing={3}>
          <SubmitErrorMessage />
          <Grid2>
            <TextField
              label={translate('pages.login.inputs.emailAddress.label')}
              name="emailAddress"
              parse={(v: string) => v?.toLowerCase() ?? ''}
              placeholder={translate('pages.login.inputs.emailAddress.placeholder')}
              type="email"
            />
          </Grid2>
          <Grid2>
            <PasswordField
              label={translate('pages.login.inputs.password.label')}
              placeholder={translate('pages.login.inputs.password.placeholder')}
            />
          </Grid2>
          <Grid2>
            <SubmitButton>{translate('pages.login.button')}</SubmitButton>
          </Grid2>
        </Grid2>
        <Grid2 my="24px">
          <Typography align="center" color="textPrimary" variant="body1">
            <RichTranslationText
              tags={{
                forgot: match => (
                  <Link to="/forgot-password" underline="none">{match}</Link>
                ),
              }}
              translationKey="pages.login.links.forgotPassword"
            />
          </Typography>
        </Grid2>
      </Form>
    </EmptyLayout>
  )
}

export default LoginPage
