import {Button, Card, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useTranslate} from 'react-admin'
import {useNavigate} from 'react-router-dom'

import NotFoundIllustration from '../assets/imgs/notFoundIllustration.png'

const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <StyledCard>
      <StyledImage alt="not-found-illustration" src={NotFoundIllustration} />
      <Typography variant="subtitle1">
        {useTranslate()('pages.notFound.title')}
      </Typography>
      <Typography color="textSecondary">
        {useTranslate()('pages.notFound.message')}
      </Typography>
      <Button onClick={() => navigate(-1)} variant="contained">
        {useTranslate()('ra.action.back')}
      </Button>
    </StyledCard>
  )
}

const StyledCard = styled(Card)(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.remSpacing(1),
  height: '100%',
  justifyContent: 'center',
}))

const StyledImage = styled('img')(({theme}) => ({
  marginBottom: theme.remSpacing(8),
}))

export default NotFoundPage
