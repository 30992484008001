import {useEffect} from 'react'
import {useAuthProvider, useLogoutIfAccessDenied} from 'react-admin'
import {usePageVisibility} from 'react-page-visibility'

/*
 * A hooks that checks if the user is still logged in, every time the user navigates
 * back to the browser tab where this app runs. If the user isn't logged in, the logout
 * is triggered so that they need to re-login. Doing so prevents showing outdated
 * information to the user and even more important giving the wrong the impression of
 * still being logged in.
 */
// TODO: Publish blog post avout this generic hook
const useAuthWatcher = () => {
  const authProvider = useAuthProvider()
  const logoutIfAccessDenied = useLogoutIfAccessDenied()
  const isPageVisible = usePageVisibility() ?? true
  useEffect(() => {
    if (isPageVisible) {
      authProvider?.checkAuth({}).catch(e => {
        // Log error as automatic logouts and their redirects are hard to debug
        if (e.message !== 'UNAUTHORIZED') {
          console.error("checkAuth() raised, potentially logging user out", e)
        }
        logoutIfAccessDenied(e)
      })
    }
  }, [authProvider, isPageVisible, logoutIfAccessDenied])
}

export default useAuthWatcher
