import {MutationFunction, MutationResultWrapper, useLazyQuery} from '@apollo/client'
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {debounce} from 'lodash-es'
import {FC, ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import {useGetIdentity, useNotify, useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedDialog from '../../components/ExtendedDialog'
import ProgressButton from '../../components/ProgressButton'
import {
  MerchantCustomerUsers,
  MutationRoot,
  MutationRootStartChatArgs,
  QueryRoot,
} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'


const StartExternalChatDialog: FC<StartExternalChatDialogProps> = ({
  actionButtonText,
  channelId,
  children,
  loading,
  onClose,
  onStartChat,
  open = false,
  title,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const inputRef = useRef<HTMLInputElement>()
  useEffect(() => {
    // `focus()` has no effect if executed immediately. A workaround is to
    // request the focus asynchronously.
    open && setTimeout(() => inputRef.current?.focus(), 100)
  }, [inputRef, open])
  const {identity: {merchantId, merchantUserId} = {}} = useGetIdentity()
  const [
    selectedMerchantCustomerUser, setSelectedMerchantCustomerUser,
  ] = useState<MerchantCustomerUsers | null>()
  const [inputValue, setInputValue] = useState('')
  const [isTypingPhoneNumber, setIsTypingPhoneNumber] = useState(false)
  const [isInputValid, setIsInputValid] = useState(false)
  const [
    getMerchantCustomerUsers,
    {
      data: {merchant_customer_users: merchantCustomerUsers = []} = {},
      loading: isLoadingMerchantCustomerUsers,
    },
  ] = useLazyQuery<QueryRoot['merchant_customer_users']>(
    MERCHANT_CUSTOMER_USERS_QUERY,
    {fetchPolicy: 'cache-and-network'}
  )
  const debounceGetMerchantCustomerUsers = useMemo(
    () => debounce(getMerchantCustomerUsers, 500),
    [getMerchantCustomerUsers],
  )
  const canStartChat = (
    !!(selectedMerchantCustomerUser?.customerUser?.whatsappPhoneNumber || isInputValid) &&
      !(isLoadingMerchantCustomerUsers || loading)
  )
  const startChat = () => {
    if (!canStartChat) return
    trackEvent('START_CHAT', EventCategory.CONTACT, 'START_EXTERNAL_CHAT_DIALOG')
    onStartChat({
      variables: {
        chat: {
          channelId,
          customerUserWhatsappPhoneNumber: (
            selectedMerchantCustomerUser?.customerUser?.whatsappPhoneNumber ||
              inputValue
          ),
          owningUserId: selectedMerchantCustomerUser?.customerUser?.user?.id,
        },
      },
    }).then(() => {
      setInputValue('')
      setIsTypingPhoneNumber(false)
      setIsInputValid(false)
      setSelectedMerchantCustomerUser(null)
    }).catch(() => {
      notify('errors.unknown', {type: 'error'})
    })
  }
  const styles = useStyles()
  useEffect(() => {
    if (!merchantId) return
    debounceGetMerchantCustomerUsers({
      variables: {
        where: {
          _or: [
            {fullText: {_ilike: `%${inputValue.trim().replaceAll(/\s+/g, '%')}%`}},
            {role: {_ilike: `%${inputValue}%`}},
            {whatsappPhoneNumber: {_ilike: `%${inputValue}%`}},
          ],
        },
      },
    })
  }, [
    debounceGetMerchantCustomerUsers,
    inputValue,
    merchantId,
    merchantUserId,
  ])
  return (
    <ExtendedDialog
      aria-labelledby="form-dialog-title"
      className={styles.dialog}
      keepMounted
      onClose={() => {
        setInputValue('')
        setIsTypingPhoneNumber(false)
        setIsInputValid(false)
        setSelectedMerchantCustomerUser(null)
        onClose()
      }}
      open={open}
      // @ts-ignore
      openOnFocus
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogCloseButton
        onClick={() => {
          setSelectedMerchantCustomerUser(null)
          onClose()
        }}
      />
      <DialogContent>
        {children}
        <Autocomplete<MerchantCustomerUsers, false, false, true>
          autoHighlight
          disablePortal
          disabled={loading}
          freeSolo
          getOptionLabel={option => {
            const {
              customerUser: {whatsappPhoneNumber = ''} = {}, firstName, lastName,
            } = option as MerchantCustomerUsers
            return isTypingPhoneNumber ?
              `${whatsappPhoneNumber} • ${[firstName, lastName].join(" ")}` :
              [firstName, lastName].join(" ")
          }}
          inputValue={inputValue}
          loading={isLoadingMerchantCustomerUsers}
          onChange={(_, merchantCustomerUser) => {
            setSelectedMerchantCustomerUser(
              (merchantCustomerUser && typeof merchantCustomerUser !== 'string') ?
                merchantCustomerUser : null
            )
          }}
          onInputChange={(_, value) => {
            setIsTypingPhoneNumber(!!value?.match(IS_TYPING_PHONE_NUMBER_REGEX))
            setInputValue(value)
            setIsInputValid(!!value?.match(PHONE_NUMBER_VALIDATION_REGEX))
            setSelectedMerchantCustomerUser(null)
          }}
          filterOptions={options => options}
          open={open}
          options={selectedMerchantCustomerUser ? [] : merchantCustomerUsers}
          renderInput={params => (
            <TextField
              {...params}
              className={styles.inputText}
              inputRef={inputRef}
              placeholder={translate('dialogs.startChat.external.inputPlaceholder')}
              variant="filled"
            />
          )}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <ProgressButton
          color="primary"
          disabled={!canStartChat}
          isProgressing={loading}
          onClick={startChat}
          variant="contained"
        >
          {actionButtonText}
        </ProgressButton>
      </DialogActions>
    </ExtendedDialog>
  )
}

const MERCHANT_CUSTOMER_USERS_QUERY = gql`
  query ($where: merchant_customer_users_bool_exp!){
    merchant_customer_users(
      limit: 20
      order_by: {firstName: asc}
      where: $where
    ) {
      customerUser {
        id
        user{id}
        whatsappDisplayName
        whatsappPhoneNumber
      }
      id
      firstName
      lastName
    }
  }
`

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiAutocomplete-listbox': {
      maxHeight: theme.typography.pxToRem(320),
      paddingBottom: 0,
    },
    // TODO: This was copied from `ChatMessageFileAttachment.jsx` and should be
    //       generalized by lifting it into `ExtendedDialog` and making it available
    //       as a prop, maybe `size`? Or is this setting valuable for any dialog?
    '& .MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: theme.typography.pxToRem(593),
      maxWidth: theme.typography.pxToRem(572),
      width: '100%',
    },
    '& .MuiDialogContent-root': {
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  dialogActions: {
    paddingTop: 0,
    top: 0,
  },
  inputText: {
    '& .MuiFilledInput-root': {
      paddingTop: 0,
    },
  },
}))

const PHONE_NUMBER_VALIDATION_REGEX = /^\+?[0-9()\s]{8,20}$/

const IS_TYPING_PHONE_NUMBER_REGEX = /^\+?[0-9]/

interface StartExternalChatDialogProps {
  actionButtonText: string
  channelId: string
  children: ReactNode
  loading: boolean
  onClose: () => void
  onStartChat: MutationFunction<
    MutationResultWrapper<MutationRoot['startChat']>,
    MutationRootStartChatArgs
  >,
  open?: boolean
  title: string
}

export default StartExternalChatDialog
