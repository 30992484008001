import {useQuery} from '@apollo/client'
import {Typography} from '@mui/material'
import gql from 'graphql-tag'
import {FC, useContext, useEffect, useMemo} from 'react'
import {useGetIdentity, useTranslate} from 'react-admin'
import {Navigate, useLocation, useParams} from 'react-router-dom'
import {makeStyles} from 'tss-react/mui'

import {Chat, ChatContext, ChatList, ChatPage} from '../../components/chat'
import ChatFilterTabs from '../../components/chat/ChatFilterTabs'
import ChatHeader from '../../components/chat/ChatHeader'
import {SandboxContext} from '../../contexts/SandboxContext'
import useChatsQueryAndSubscription from '../../hooks/chat/useChatsQueryAndSubscription'
import useIsDesktop from '../../hooks/useIsDesktop'
import useIsSuperAdmin from '../../hooks/useIsSuperAdmin'
import {useUsersDisplayName} from '../../hooks/useUserDisplayName'
import {Chats, ChatTypesEnum, QueryRoot} from '../../types/graphqlSchema'
import ChatContextMenuButton from './ChatContextMenuButton'
import StartInternalChatButton from './StartInternalChatButton'

const InternalChatPage = () => {
  const translate = useTranslate()
  const {chatId} = useParams<{chatId: string}>()
  const isDesktop = useIsDesktop()
  const {isSandbox} = useContext(SandboxContext)
  const hashValue = useLocation().hash.replace('#', '')
  const chatFilter = CHAT_FILTERS.find(f => f === hashValue) ?? CHAT_FILTERS[0]
  const {identity: {id: userId = undefined} = {}} = useGetIdentity()
  const {
    chats,
    fetchEarlierChats,
    hasEarlierChats,
    isFetchingMore,
    loading,
    onResetFetchEarlierChatsOffset,
  } = useChatsQueryAndSubscription(
    {
      chatUsers: {userId: {_eq: userId}},
      isArchived: {_eq: chatFilter === 'archived'},
      type: {_eq: 'INTERNAL'},
    },
    CHAT_FIELDS,
    chatFilter,
    {skip: !userId}
  )
  useEffect(onResetFetchEarlierChatsOffset, [chatFilter, onResetFetchEarlierChatsOffset])
  const {data: {chats_by_pk: selectedChat} = {}} = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY, {skip: !chatId, variables: {chatId}}
  )
  const {classes: styles} = useStyles({isProductNews: !!selectedChat?.isProductNews})
  const chatUsersDisplayName = useChatUsersDisplayName(selectedChat)
  const isChatToolbarVisible =
    (useIsSuperAdmin() && selectedChat?.isProductNews) ||
    !selectedChat?.isProductNews
  if (!chatId && isDesktop && chats?.length) {
    return <Navigate to={`/team-chat/${chats[0].id}#${chatFilter}`} />
  }
  return (
    <ChatPage
      chatListHeader={
        <ChatFilterTabs
          chatType={ChatTypesEnum.Internal}
          filters={CHAT_FILTERS.map(f => ({
            hasUnreadChatMessages: {'all': !!chats?.some(c => !!c.unreadMessageCount)}[f],
            name: f,
          }))}
          value={chatFilter}
        />
      }
      startNewChatButton={!isSandbox && <StartInternalChatButton />}
      title={translate('chat.internal.title')}
    >
      <ChatList
        chatTitle={ChatTitle}
        chats={chats ?? []}
        fetchMoreChats={fetchEarlierChats}
        hasMessagePreview={false}
        hasMoreChats={hasEarlierChats}
        isFetchingMore={isFetchingMore}
        loading={loading}
        rightComponent={ChatListRightComponent}
        selectedChatId={chatId as string}
      />
      {!!chatId && (
        <ChatContext.Provider value={{chatId}}>
          <Chat
            header={
              <ChatHeader
                subtitle={
                  selectedChat?.isProductNews ?
                    undefined :
                    (selectedChat?.name ? chatUsersDisplayName : undefined)
                }
                title={
                  selectedChat?.isProductNews ?
                    translate('chat.internal.productNewsChatTitle') :
                    (selectedChat?.name || chatUsersDisplayName)
                }
              >
                {selectedChat && (
                  <ChatContextMenuButton
                    chat={selectedChat}
                    className={styles.chatContextMenuButton}
                  />
                )}
              </ChatHeader>
            }
            isChatToolbarVisible={isChatToolbarVisible}
            type={ChatTypesEnum.Internal}
          />
        </ChatContext.Provider>
      )}
    </ChatPage>
  )
}

const ChatListRightComponent: FC<ChatListRightComponentProps> = ({chat}) => (
  <ChatContextMenuButton
    chat={chat}
    className={
      useStyles({isProductNews: chat.isProductNews}).classes.chatContextMenuButton
    }
  />
)

const ChatTitle: FC<ChatTitleProps> = ({chat}) => {
  const translate = useTranslate()
  const {classes: styles} = useStyles({})
  const chatUsersDisplayName = useChatUsersDisplayName(chat)
  return (
    <Typography
      className={chat.unreadMessageCount ? styles.unreadMessageItem : ''}
      color="textPrimary"
      variant="body1"
    >
      {chat.isProductNews ?
        translate('chat.internal.productNewsChatTitle') :
        (chat.name || chatUsersDisplayName)}
    </Typography>
  )
}

const useChatUsersDisplayName = (chat: Chats | undefined) => {
  const {identity: {id: userId} = {}} = useGetIdentity()
  const usersDisplayName = useUsersDisplayName()
  return useMemo(
    () => usersDisplayName(chat?.chatUsers
      .map(({user}) => user)
      .filter(({id}) => id !== userId)
    ),
    [chat?.chatUsers, userId, usersDisplayName]
  )
}

const useStyles = makeStyles<MakeStylesOptions>()((_, {isProductNews}) => ({
  chatContextMenuButton: {
    visibility: isProductNews ? 'hidden' : 'visible',
  },
  unreadMessageItem: {
    fontWeight: 900,
  },
}))

const CHAT_FILTERS: ChatFilter[] = ['all', 'archived']

const CHAT_FIELDS = `
  chatMessages(limit: 1, order_by: {insertionTimestamp: desc}) {
    chatMessageTemplate{id text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index} value}
    insertionTimestamp
    text
    timestamp
  }
  chatUsers{user{id merchantUser{firstName id lastName}}}
  id
  insertionTimestamp
  isArchived
  isProductNews
  lastActivityTimestamp
  name
  unreadMessageCount
  userChats{chatId isFlagged}
`

const CHAT_QUERY = gql`
  query($chatId: uuid!){chats_by_pk(id: $chatId){${CHAT_FIELDS}}}
`

interface ChatTitleProps {
  chat: Chats
}

interface MakeStylesOptions {
  isProductNews?: boolean
}

interface ChatListRightComponentProps {
  chat: Chats
}

export default InternalChatPage
