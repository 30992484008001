import {useMutation} from '@apollo/client'
import {Button, Grid2, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import gql from 'graphql-tag'
import {useState} from 'react'
import {Link, Loading, useTranslate} from 'react-admin'
import {Navigate, useParams} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, PasswordFieldError, SubmitButton, SubmitErrorMessage,
} from '../components/ExtendedHookForm'
import RichTranslationText from '../components/RichTranslationText'
import theme from '../theme'
import {useAuthCheck} from '../utils/authProvider'
import {actionErrorCode} from '../utils/errors'

const ResetPasswordPage = () => {
  const translate = useTranslate()
  const styles = useStyles()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const {passwordResetCode} = useParams<{passwordResetCode: string}>()
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION)
  const validate = values => ({
    errors: {
      ...(!(
        (/\d/).test(values.password) &&
        values.password.length >= 8 &&
        (/\p{Ll}/u).test(values.password) &&
        (/\p{Lu}/u).test(values.password)
      ) && {
        password: {
          message: translate('validations.password.missingSomeChars'),
          type: 'validation',
        },
      }),
      ...(values.repeatPassword !== values.password && {
        repeatPassword: {
          message: translate('validations.password.doNotMatch'),
          type: 'validation',
        },
      }),
    },
    values,
  })
  const submit = v => resetPassword({
    variables: {newPassword: v.password, passwordResetCode},
  })
    .then(() => setIsPasswordReset(true))
    .catch(e => {
      const errorCode = actionErrorCode(e)
      const errorMessage = translate(
        errorCode ? `pages.resetPassword.errors.${errorCode}` : 'ra.message.error'
      )
      return {errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Navigate to="/" />
  }
  return (
    <EmptyLayout>
      {isPasswordReset ? (
        <Grid2
          alignItems="stretch"
          className={styles.container}
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Grid2>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.resetPassword.passwordResetSuccess.title')}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography align="center" color="textSecondary">
              {translate('pages.resetPassword.passwordResetSuccess.subtitle')}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography align="center" color="textSecondary">
              {translate('pages.resetPassword.passwordResetSuccess.nextStep')}
            </Typography>
          </Grid2>
          <Grid2>
            <Button color="primary" fullWidth href="/login" variant="contained">
              {translate('pages.resetPassword.passwordResetSuccess.login')}
            </Button>
          </Grid2>
        </Grid2>
      ) : (
        <Form submit={submit} validate={validate}>
          <Grid2 alignItems="stretch" container direction="column" spacing={2}>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.resetPassword.title')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              <RichTranslationText
                tags={{
                  register: match => (
                    <Link to="/signup" underline="none" variant="body1">
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.resetPassword.register"
              />
            </Typography>
          </Grid2>
          <Grid2 alignItems="stretch" container direction="column" spacing={3}>
            <SubmitErrorMessage />
            <Grid2>
              <PasswordField
                label={translate('pages.resetPassword.fields.newPassword.label')}
                name="password"
                placeholder={
                  translate('pages.resetPassword.fields.newPassword.placeholder')
                }
              />
              <PasswordFieldError/>
            </Grid2>
            <Grid2>
              <PasswordField
                label={translate('pages.resetPassword.fields.repeatNewPassword.label')}
                name="repeatPassword"
                placeholder={
                  translate('pages.resetPassword.fields.repeatNewPassword.placeholder')
                }
              />
            </Grid2>
            <Grid2>
              <SubmitButton>
                {translate('actions.confirm')}
              </SubmitButton>
            </Grid2>
          </Grid2>
          <Grid2 my="24px">
            <Typography align="center" color="textPrimary" variant="body1">
              <RichTranslationText
                tags={{
                  login: match => (
                    <Link to="/login" underline="none">{match}</Link>
                  ),
                }}
                translationKey="pages.resetPassword.login"
              />
            </Typography>
          </Grid2>
        </Form>
      )}
    </EmptyLayout>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiGrid': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: '100%',
    margin: 'auto',
    width: theme.remSpacing(56),
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}))

const RESET_PASSWORD_MUTATION = gql`
  mutation($newPassword: String!, $passwordResetCode: String!){
    resetPassword(newPassword: $newPassword, passwordResetCode: $passwordResetCode)
  }
`

export default ResetPasswordPage
